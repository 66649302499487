/* Color Variables */
$secondary-color: #062121;
$primary-color: #7C9D96;
$text-color: #5c5c77;
$text-color-light: #8585a4;
$text-color-dark: #062121;
$body-color: #fff;
$border-color: #ededf1;
$black: #000;
$white: #fff;
$light: #f8f9fe;
$gray: #f8f8f8;

/* Font Variables */
$primary-font: 'Poppins', sans-serif;
$secondary-font: 'futura-bold';
$icon-font: 'themify';
